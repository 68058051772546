@font-face {
    font-family: 'GT Pressura Mono Bold';
    src: url('../public/fonts/GT-Pressura-Mono-Bold.ttf') format('truetype');
}

@font-face {
    font-family: 'Source Sans Pro';
    src: url('../public/fonts/SourceSansPro-Regular.ttf') format('truetype');
    /* Add other font weights and styles similarly if needed */
}


body {
    background-image: url('../public/assets/images/bg.png');
    background-size: cover;
  }